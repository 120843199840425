<script setup>

import auth from "../auth"
import statics from "../static"
import {useRouter} from "vue-router"
import {Snackbar} from "@varlet/ui"
import {ref} from "vue"

const router = useRouter()
const props = defineProps({
  email: {
    type: String,
    required: true
  }
})

const loading = ref(false)
const resendLoading = ref(false)
const form = ref(null)
const code = ref(null)

const resend = () => {
  resendLoading.value = true

  auth.resend({email: props.email})
      .then(data => {
        resendLoading.value = false
      })
      .catch(error => {
        resendLoading.value = false
      })
}

const submit = () => {
  loading.value = true

  auth.verify({email: props.email, code: code.value})
      .then(data => {
        loading.value = false
        router.push({
          name: "Login"
        })
      })
      .catch(error => {
        loading.value = false

        if (error.response.status === 422) {
          console.log(error)
        } else {
          Snackbar['error'](statics.server_error)
        }
      })
}

</script>

<template>
  <var-skeleton :rows="2" :loading="loading">
    <var-form ref="form">
      <var-input
          placeholder="Please input code"
          :rules="[v => !!v || 'The code cannot be empty']"
          v-model="code"
          class="mb-3"
      />
    </var-form>

    <var-space>
      <var-button @click="resend" type="primary" :loading="resendLoading">Resend</var-button>
      <var-button @click="submit" type="primary" :loading="loading">Verify</var-button>
    </var-space>
  </var-skeleton>
</template>

<style>

</style>